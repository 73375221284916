//
//
//
//
//
//
//
//
export default {
  props: {
    info: {
      type: String,
      default: "确定"
    },
    before: {
      type: String
    },
    after: {
      type: String
    }
  },
  data: function data() {
    return {
      beforeColor: {
        "background": this.before
      },
      afterColor: {
        "background": this.after
      }
    };
  }
};